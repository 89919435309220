import React from 'react'
import * as config from '../blog-config'
import Helmet from 'react-helmet/es/Helmet'

export const Seo = (data) => {

  const isPost = data.for && data.for === 'post'
  const isPage = data.for && data.for === 'page'

  const Title = isPost
    ? `${data.frontmatter.title} - ${config.shortSiteTitle}`
    : config.siteTitle

  const description = isPost
    ? data.frontmatter.excerpt
    : config.siteDescription

  const url = isPost
    ? config.siteUrl + config.pathPrefix + data.frontmatter.path
    : isPage
      ? config.siteUrl + config.pathPrefix + data.path
      : config.siteUrl

  const keywords = isPost
    ? data.frontmatter.keywords
    : config.siteKeywords

  return (
    <Helmet
      htmlAttributes={{
        lang: config.siteLanguage,
        prefix: 'og: http://ogp.me/ns#'
      }}
    >
      {/* General tags */}
      <title>{Title}</title>
      <meta name="description" content={description}/>
      <meta name="keywords" content={keywords}/>

      {/* OpenGraph tags */}
      <meta property="og:url" content={url}/>
      <meta property="og:title" content={Title}/>
      <meta property="og:description" content={description}/>
      {/*<meta property="og:image" content={image} />*/}
      <meta property="og:type" content="website"/>

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary"/>
      <meta
        name="twitter:creator"
        content={config.authorTwitterAccount ? config.authorTwitterAccount : ''}
      />
    </Helmet>
  )
}